jQuery(document).ready(function ($) {

    $('.av-main-nav').on('click', '.menu-item-has-children', function () {
        $('.menu-item-has-children').removeClass('active');
        $(this).addClass("active");
        $('body').addClass('sub-menu-active');
    });

    if (!$('.sub-menu .close-button').length) {
        $('.sub-menu').append('<a class="close-button" href="#"></a>');
        $('.av-main-nav').on('click', '.sub-menu .close-button', function(e){
            e.preventDefault();
            e.stopPropagation();
            $('.menu-item-has-children').removeClass('active');
            $('body').removeClass('sub-menu-active');
        });
    }
    
    if($('.custom-slider .avia-caption-content').length){
        $('.custom-slider .avia-caption-content').each(function(){
            var $span = $(this).find('span');
            var link = $span.data('link');
            var $li = $(this).closest('li');
            
            if(link){
                var text = $span.text();
                
                //Replace span with a tag 
                $span.replaceWith('<a href="' + link +'" class="archive-link" target="_blank">' + text +'</a>');
            }
            //move caption lightbox wrapper
            $(this).closest('.avia-caption').appendTo($li);
        });
    }
    $(document).on("change", "#tribe-tickets .tribe-tickets-quantity", function () {
        $("#tribe-tickets .tribe-tickets-quantity").val($(this).val())
    })
});
  